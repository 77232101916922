import styled from 'styled-components'
import SEO from '../components/seo'
import React from 'react'
import Layout from '../layouts'
import { Container, Col, Row } from 'reactstrap';

const StyledTextContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.blackWhite};
  margin-top: -110px;
  padding: 1.2rem;
  min-height: 90vh;
`
const Text = styled.p`
font-size: 1.2rem;
line-height: 1.5;
text-align: left;

@media (min-width: 768px) {
  text-align: left;
}
`
const Title = styled.h1`
margin-bottom: 2.2rem;
font-size: 2rem;
overflow-wrap: normal;
max-width: 1200px;

@media (min-width: 960px) {
  font-size: 3.6rem;
}


  
`
const Pointer = styled.p`
margin-bottom: 1rem;
font-size: 1.5rem;
overflow-wrap: normal;
max-width: 1200px;

@media (min-width: 960px) {
  font-size: 2rem;
}
  
`;

const RulesPage = (props) => {
  return (
    <>
      <Layout path={props.location.pathname}>
        <SEO title='Zasady' path={props.location.pathname} />
              <StyledTextContainer> 
                 <Container style={{paddingTop:140}}>
              <Title>Regulamin obiektu</Title> 
              <Text>Obiekt położony jest w granicach lasu, co daje ciszę i spokój. Taki też wypoczynek oferujemy i zapraszamy osoby ceniące sobie tę formę wypoczynku. 
                Dlatego prosimy o powstrzymanie się od słuchania muzyki na zewnątrz, 
                a przed dokonaniem rezerwacji weryfikację swoich oczekiwań co do pobytu.
<br/><br/> </Text>
              <Text>
• Obowiązuje całkowity zakaz palenia we wszystkich domkach.<br/><br/>
• Zakaz używania w domkach otwartego ognia – świeczki, lampki naftowe, gazowe itd.<br/><br/>
• Zakaz używania własnych kuchenek elektrycznych i gazowych, oraz grzałek elektrycznych.<br/><br/>
• Odwiedziny osób niezameldowanych w domkach – wyłącznie po wcześniejszym poinformowaniu, i tylko w
godzinach od 8:00 do 22:00.<br/><br/>
• Dzieci i młodzież mogą przebywać w domkach i w strefie Spa tylko pod opieką dorosłych.<br/><br/>
• Bezwzględna cisza nocna obowiązuje w godz. 22:00 - 06:00<br/><br/>
• Zakaz urządzania imprez w domkach, nieprzestrzeganie wiąże się z natychmiastowym rozwiązaniem
umowy, bez zwrotu należności za pozostały pobyt.<br/><br/>
• Jesteśmy przyjaźnie nastawieni do zwierząt, jednak prosimy o sprzątanie nieczystości po swoich
pupilach oraz dbanie aby nie niszczyły obiektu. Zwierzęta na terenie obiektu mogą pozostawać tylko pod
opieką właściciela i zabezpieczone tak aby nie stwarzały zagrożenie dla innych.
Nieprzestrzeganie grozi naliczeniem opłaty za sprzątanie oraz zniszczenia.
Pobieramy opłatę za każde zwierzę 50 zł za 2 doby.<br/><br/>
• Podczas pobytu będą Państwo mogli korzystać z jacuzzi i sauny. Opłata za czas jacuzzi to 200 zł - 2 godziny, oraz 50 zł każda następna. 
Opłata za korzystanie z sauny to 120 zł godzina oraz całodzienne korzystanie z 
jacuzzi 300 zł z samodzielnym nagrzaniem wody.<br/><br/>
• Podczas korzystania z mini Spa obowiązuje całkowity zakaz spożywania napojów, w tym alkoholowych
oraz jakichkolwiek pokarmów. Zabrania się korzystać z sauny i jacuzzi osobą pod wpływem alkoholu.<br/><br/>
• Prosimy o segregacje odpadów do wyznaczonych worków, odpady bio prosimy pozostawić w osobnym
worku przed obiektem, za pozostawione bez segregacji śmieci będziemy naliczać opłatę w wysokości 50 zł <br/><br/>
• Prosimy o nie przenoszenie mebli - za powstałe przy przenoszeniu zniszczenia będziemy obciążać kosztami wynajmujących.<br></br><br/><br/>
              </Text>
        </Container>
      </StyledTextContainer>
      </Layout>  
    </>
  )
}

export default RulesPage